<template>
  <div>
    <table-local-v-1-widget
      :items="items"
      :columns="columns"
      :actions="actions"
      :loading="loading"
      @clickView="row => $emit('clickView', row)"
    >
      <template #c1="{row}">
        <span>{{ row.image_datas.length }} รูป</span>
      </template>
    </table-local-v-1-widget>
    <!-- <pre>{{ items }}</pre> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      columns: [
        {
          label: 'วันที่ (วัน-เดือน-ปี)',
          field: 'created_at',
          formatFn: val => this.$date(val).format('DD-MM-YYYY HH:mm'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ผู้เข้าร่วม AF',
          field: 'user_profile_data_as_phone_number',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ชื่อ-นามสกุล',
          field: 'bank_account_data_as_account',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ยอดสุทธิ',
          field: 'transaction_data_as_withdraw_amount',
          type: 'currency',
          tdClass: 'text-center text-success',
          thClass: 'text-center',
        },

        {
          label: 'หลักฐานการโอน',
          field: 'c1',
          thClass: 'text-center',
          tdClass: 'text-center',
          type: 'custom',
        },
      ],
      loading: false,
      actions: [{ keyEmit: 'clickView', iconName: 'EyeIcon', label: 'ดูเพิ่มเติม' }],
    }
  },
  created() {
    this.getWithdrawApprove()
  },
  methods: {
    async getWithdrawApprove() {
      this.loading = true
      const resp = await this.api.get('api/admin/affiliate/affiliate-withdraw/index?filter=approve').catch(() => {})
      // console.log('getWithdrawApprove', resp)
      if (resp) {
        this.items = [...resp]
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
